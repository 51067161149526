<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="none" name="plantCd" v-model="searchParam.plantCd" @input="getList"/>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :required="true"
            type="year"
            default="today"
            label="기준년도"
            name="wasteYear"
            @input="getList"
            v-model="searchParam.wasteYear"
          />
        </div>
      </template>
    </c-search-box>
    <div class="col-12" style="background:#fff;">
      <apexchart 
        ref="chart1" 
        height="300" 
        type="line"
        :width="chart.chartWidth"
        :options="chart.chartOptions" 
        :series="chart.series"></apexchart>
    </div>
    <br>
    <div class="col-12" style="background:#fff;">
      <apexchart 
        ref="chart2" 
        height="300" 
        type="line"
        :width="chart2.chartWidth"
        :options="chart2.chartOptions" 
        :series="chart2.series"></apexchart>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'waste-uc-manage',
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      searchParam: {
        wasteYear: '',
        plantCd: null,
      },
      chart: {
        chartOptions: {
          title: {
            text: '생산량-폐기물 원단위 추이분석'
          },
          chart: {
            type: 'line',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            toolbar: {
              show: true
            },
            zoom: {
              enabled: false
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth',
          },
          xaxis: {
            categories: ['1월','2월','3월','4월','5월','6월','7월','8월','9월','10월','11월','12월'],
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          yaxis: {
            title: {
              text: '원단위분석'
            }
          },
          /* eslint-disable no-unused-vars */
          // tooltip: {
          //   custom: function({ series, seriesIndex, dataPointIndex, w }) {
          //     return (
          //       JSON.stringify(w.globals.categoryLabels[dataPointIndex]) +

          //       "</span>" +
          //       "</div>"
          //     );
          //   }
          // },
          fill: {
            opacity: 1
          },
          markers: {
            size: 1,
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
          }
        },
        series: [],
        chartWidth: '100%',
      },
      chart2: {
        chartOptions: {
          title: {
            text: '처리비용-폐기물 원단위 추이분석'
          },
          chart: {
            type: 'line',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            toolbar: {
              show: true
            },
            zoom: {
              enabled: false
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth',
          },
          xaxis: {
            categories: ['1월','2월','3월','4월','5월','6월','7월','8월','9월','10월','11월','12월'],
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          yaxis: {
            title: {
              text: '원단위분석'
            }
          },
          fill: {
            opacity: 1
          },
          markers: {
            size: 1,
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            // formatter: function(label, opts) {
            //   return label + " - " + opts.w.globals.series[opts.seriesIndex]
            // }
          }
        },
        series: [],
        chartWidth: '100%',
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.env.waste.volume.uc.url;
      this.getList();
    },
    getList() {
      let isAllSearch = false;
      if (this.searchParam.plantCd.split(',').length > 1) {
        isAllSearch = true;
      }
      if (this.searchParam.wasteYear !== null) {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = this.searchParam;
        this.$http.request((_result) => {
          if (isAllSearch) {
            // this.grid.data = this.$_.filter(_result.data, {tvTypeCdTop : 'A' });
            // this.grid2.data = this.$_.filter(_result.data, {tvTypeCdTop : 'F' });
            // this.grid3.data = this.$_.filter(_result.data, {tvTypeCdTop : 'O' });
            // this.grid4.data = this.$_.filter(_result.data, {tvTypeCdTop : 'J' });
            // this.grid5.data = this.$_.filter(_result.data, {tvTypeCdTop : 'Q' });
          } else {
            let sdata = this.$_.filter(_result.data, {tvTypeCdTop : 'O', totalFlag: 'X' });
            this.chart.series = [];
            this.$_.forEach(sdata, item => {
              this.chart.series.push({
                name:item.envWasteMstName,
                data: [item.tv01,item.tv02,item.tv03,item.tv04,item.tv05,item.tv06,item.tv07,item.tv08,item.tv09,item.tv10,item.tv11,item.tv12]
              })
            })
            
            let sdata2 = this.$_.filter(_result.data, {tvTypeCdTop : 'Q', totalFlag: 'X' });
            this.chart2.series = [];
            this.$_.forEach(sdata2, item => {
              this.chart2.series.push({
                name:item.envWasteMstName,
                data: [item.tv01,item.tv02,item.tv03,item.tv04,item.tv05,item.tv06,item.tv07,item.tv08,item.tv09,item.tv10,item.tv11,item.tv12]
              })
            })
          }
          this.$refs['chart1'].refresh();
          this.$refs['chart2'].refresh();
        },);
      } else {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message:
          '기준년도를 선택하세요',
          type: 'warning', // success / info / warning / error
        });
      }
    },
  }
};
</script>
